html, body{
  background-color: #1b1e25;
}

*{
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

ul li{
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #1b1e25;
  padding: calc(5% - 0.5rem);
  padding-top: calc(74px - 1rem);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Section{
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #666;
}

.Section:nth-child(4){
  border: 0;
  padding-bottom: 4rem;
}

.SectionTitle{
  color: #fff;
  text-align: left;
  margin-left: calc(5% - 0.5rem);
}

.CardsContainer{
  display: flex;
  flex-wrap: wrap;
}

.CardWrapper{
  padding: 0.5rem;
  width: 50%;
}

.CardWrapper.CardWrapperMain{
  padding: 0.5rem;
  width: 100%;
}

.Card{
  border-radius: 10px;
  margin: auto;
  background-color: #282c34;
  border-radius: 10px;
  overflow: hidden;
}

.Card-header{
  background: #313640;
  padding: 1rem;
  color: #fff;
  text-align: left;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.Card-content{
  padding: 1rem;
  display: flex;
  align-items: center;
}

.Card-content .Chart{
  /* width: 30%; */
  width: 80%;
  margin: auto;
}

.Card-content .Labels{
  width: 70%;
  padding-right: 1rem;
  text-align: left;
  color: #fff;
  font-size: 0.9rem;
  padding-left: 1.5rem;
}

.Card-content .Labels p{
  margin: 8px 0;
}

.Card-content .Labels b{
  text-transform: uppercase;
  color: #999999;
  font-size: 0.8rem;
}

.CardWrapper.CardWrapperMain .Card-content{
  flex-direction: column;
}

.CardWrapper.CardWrapperMain .Card-content .Chart{
  width: 50%;
}

.CardWrapper.CardWrapperMain .Card-content .Labels{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1rem;
  text-align: center;
}

.CardWrapper.CardWrapperMain .Card-content .Labels b{
  display: block;
  margin-bottom: 8px;
}

.Navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 5%;
  display: flex;
  justify-content: space-between;
  background: #090a0d;
  color: #fff;
  z-index: 20;
}

.Navbar .Logo{
  display: block;
  height: 30px;
  width: auto;
}

.Navbar ul{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.Navbar .Navlink a{
  display: block;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
}

.Navbar .Navlink a span{
  display: block;
}

.LoginWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
}

.LoginLogo{
  display: block;
  /* width: 100px; */
  width: 180px;
  height: auto;
  margin: auto;
}

.lds-ellipsis{
  margin-top: 2rem !important;
}
.lds-ellipsis div{
  background-color: #fff !important;
}


@media(min-width: 768px){
  .App{
    overflow: hidden;
  }

  .Section{
    width: 50%;
    float: left;
    border-right: 1px solid #666;
    padding: 0;
    margin: 0;
    margin-top: 2rem;
    padding: 1rem;
    border-bottom: none;
  }

  .Section:nth-child(even){
    border-right: none;
  }

  .CardWrapper.CardWrapperMain.LineChart{
    margin-top: -10px;
    margin-bottom: 1rem;
  }

  .Section:nth-child(3), .Section:nth-child(4){
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

}

.BottomNav{
  background: rgba(9,10,13,0.6);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
}

.BottomNav .ButtonGroup{
  display: flex;
  margin-right: -1px;
}

.BottomNav .ButtonGroup button{
  background: none;
  border: none;
  color: #ddd;
  font-weight: 400;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 0.4rem 1rem;
  min-width: 100px;
  justify-content: center;
  margin: 0;
}

.BottomNav .ButtonGroup button:first-child{
  border-radius: 20px 0 0 20px;
  padding-left: 1.4rem;
}

.BottomNav .ButtonGroup button:last-child{
  border-radius: 0 20px 20px 0;
  padding-right: 1.4rem;
  border-left: 0;
}

.ButtonGroup button.active{
  color: #fff;
  background-color: rgba(15,250,0,0.4);
  font-weight: 600;
}